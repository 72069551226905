@import "https://fonts.googleapis.com/css2?family=Playfair+Display&family=Vast+Shadow&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 14px;
  line-height: 1.5;
}

@media (min-width: 300px) and (max-width: 1499px) {
  html {
    font-size: calc(.916667vw + 11.25px);
  }
}

@media (min-width: 1500px) {
  html {
    font-size: 25px;
  }
}

body {
  background-image: url("beer.11a551db.jpg");
  background-repeat: none;
  color: #333;
  min-height: 100vh;
  background-size: cover;
  grid-template: "top top top" 1vh
                 "left content right"
                 "bottom bottom bottom" 1vh
                 / auto min(820px, 95vw) auto;
  font-family: Playfair Display, serif;
  display: grid;
}

main {
  text-align: center;
  flex-direction: column;
  grid-area: content;
  align-items: center;
  gap: 2rem;
  display: flex;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1.1;
}

h1 {
  font-family: Vast Shadow, cursive;
  font-size: 3rem;
}

iframe {
  height: 50vh;
  width: 100%;
  border: none;
  padding: 1rem;
}

button {
  box-shadow: none;
  cursor: pointer;
  border: none;
  padding: .5rem;
}

#greeting {
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 3rem 1rem;
  display: flex;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
}

#greeting p {
  margin-top: 1rem;
}

#cohorts {
  width: 100%;
}

#cohorts button {
  color: #fff;
  background-color: #333;
  border-radius: .5rem .5rem 0 0;
}

#cohorts button.active {
  color: #333;
  background-color: #fff;
}

#cohorts button:not(.active):hover, #cohorts button:not(.active):focus {
  background-color: gray;
}

#cohorts p {
  padding: 0;
}

#cohorts > div {
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
}

/*# sourceMappingURL=index.ab527420.css.map */
