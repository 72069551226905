@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Vast+Shadow&display=swap');

// mixin for setting the base font-size in response to screen size
// https://ryanfeigenbaum.com/easy-responsive-typography/
@mixin fluid-typography($minFont, $maxFont, $minBreakpoint, $maxBreakpoint) {
  $maxLessOne: $maxBreakpoint - 1;
  $avg: calc(($maxFont + $minFont) / 2);
  font-size: #{$minFont}px;
  
  @media (min-width: #{$minBreakpoint}px) and (max-width: #{$maxLessOne}px) {
    font-size: #{$avg}px;
    font-size: calc(#{$minFont}px + (#{$maxFont} - #{$minFont}) * (100vw - #{$minBreakpoint}px) / (#{$maxBreakpoint} - #{$minBreakpoint}))
  }
  
  @media (min-width: #{$maxBreakpoint}px) {
    font-size: #{$maxFont}px;
  }
}

$contentWidth: 820px;
$textColor: rgb(51, 51, 51);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  line-height: 1.5;
  @include fluid-typography(14, 25, 300, 1500);
}

body {
  background-image: url('images/beer.jpg');
  background-repeat: none;
  background-size: cover;
  color: $textColor;
  display: grid;
  font-family: 'Playfair Display', serif;
  grid-template-areas:
    'top top top'
    'left content right'
    'bottom bottom bottom';
  grid-template-columns: auto min($contentWidth, 95vw) auto;
  grid-template-rows: 1vh auto 1vh;
  min-height: 100vh;
}

main {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-area: content;
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1.1;
}

h1 {
  font-family: 'Vast Shadow', cursive;
  font-size: 3rem;
}

iframe {
  border: none;
  height: 50vh;
  padding: 1rem;
  width: 100%;
}

button {
  border: none;
  box-shadow: none;
  cursor: pointer;
  padding: 0.5rem;
}

#greeting {
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 3rem 1rem;

  p {
    margin-top: 1rem;
  }
}

#cohorts {
  width: 100%;

  button {
    background-color: $textColor;
    border-radius: 0.5rem 0.5rem 0 0;
    color: white;

    &.active {
      background-color: white;
      color: $textColor;
    }

    &:not(.active) {
      &:hover, &:focus {
        background-color: gray;
      }
    }
  }

  p {
    padding: 0;
  }

  > div {
    align-items: center;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    display: flex;
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }
}
